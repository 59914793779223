.check-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

p {
    font-size: 1.2em;
    margin-bottom: 10px;
} 
